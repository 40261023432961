const {
  default: LocalApiService,
} = require("../api_service/local_api_service");
const {
  default: RestaurantApiService,
} = require("../api_service/restaurant_api_service");
const { MTPPrint } = require("./mtp");

export const printReceipt = async (
  printerModel,
  orderOrId,
  directLocal,
  currency
) => {
  const response = await RestaurantApiService().getRestaurantOrderByIdCall(
    orderOrId
  );
  const order = response.data;
  switch (printerModel) {
    case "USB-EPOS80300":
      if (directLocal) {
        LocalApiService().receiptPrintCall(order.id);
      } else {
        RestaurantApiService().printRequestCall(order.id);
      }
      break;
    case "BT-MTP-3":
      MTPPrint(order, currency, 3);
      break;
    case "BT-MTP-2":
      MTPPrint(order, currency, 2);
      break;
    case "BT-MTP-3-v2":
      MTPPrint(order, currency, 3, "v2");
      break;
    case "BT-MTP-2-v2":
      MTPPrint(order, currency, 2, "v2");
      break;
    default:
      break;
  }
};
